<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div>
    <mx-connect-widget ref="mxRef" :on-event="handleWidgetEvent" :widget-url="mxConnectUrl" />
    <!-- this div is visible when finicity portal is connected -->
      <div
        id="connect-container"
        v-if="showFloatDiv && !isLoading"
        class="d-flex justify-content-start iframe-header"
      >
        <label v-if="show_manual_link"
          >Having trouble?<button
          @click="showManual()"
            type="button"
            class="ml-2 align-self-center btn-manual-link"
          >
            Enter Banking Manually
          </button>
        </label>
      </div>
    
    <div class="container">
      <div v-if="show !== constants.manual_review">
        <div class="progress-bar-row">
          <div class="row">
            <div class="col-2"></div>
            <div class="col-2">
              <label v-if="progress === 20" class="progress-label-20"
                >{{ progress }}%</label
              >
            </div>
            <div class="col-2">
              <label v-if="progress === 40" class="progress-label-40"
                >{{ progress }}%</label
              >
            </div>
            <div class="col-2">
              <label v-if="progress === 60" class="progress-label-60"
                >{{ progress }}%</label
              >
            </div>
            <div class="col-2">
              <label v-if="progress === 80" class="progress-label-80"
                >{{ progress }}%</label
              >
            </div>
            <div class="col-2">
              <label v-if="progress === 100" class="progress-label-100"
                >{{ progress }}%</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-progress
                height="4px"
                :value="progress"
                :max="progressMax"
                class="mb-3 progress-bar-element"
              ></b-progress>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <svg
                @click="showModal"
                class="info-icon"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                width="30"
                height="30"
                fill="#ffffff"
              >
                <title>A__64</title>
                <path
                  d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45C95,25.2,74.8,5,50,5z M50,87c-20.4,0-37-16.6-37-37s16.6-37,37-37
                s37,16.6,37,37C87,70.4,70.4,87,50,87z M45,25c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S45,27.8,45,25z M61,70.4V73c0,1.1-0.9,2-2,2H41
                c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V46h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
                l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C60.4,68.7,61,69.5,61,70.4z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show === constants.enter_name_phone">
        <div v-if="!isLoading">
          <div class="row label-row">
            <div class="col-3"></div>
            <div class="col-6">
              <span class="setup-label">
                Setup Your
                <br />CanPay Account
              </span>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row enter-name-row-gap first-row-gap">
            <div class="col-12">
              <div class="form-group">
                <span class="form-control-icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 50 62.5"
                    style="enable-background: new 0 0 50 62.5"
                    xml:space="preserve"
                    height="20"
                    width="20"
                  >
                    <path
                      d="M32.3,32.1c0.2-0.8,0.4-1.6,0.4-2.5v-8.9c0-4.8-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v8.9c0,0.9,0.2,1.7,0.4,2.4
	c-2.3,0.7-4.4,2.2-6.1,4.2c-2.4,3-3.8,7-3.8,11.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.7,0.6,1.4,1.4,1.4h33.9c0.7,0,1.4-0.6,1.4-1.4
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1C42.2,40.3,38,33.9,32.3,32.1z M17.8,20.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v8.9
	c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1V20.8z M11.5,38c1.4-1.7,3.2-2.9,5.1-3.4c1.6,2.3,4.2,3.8,7.3,3.8c3,0,5.7-1.5,7.3-3.8
	c4.5,1.2,7.8,6,8.2,11.8h-31C8.6,43.2,9.7,40.3,11.5,38z"
                    />
                  </svg>
                </span>
                <input
                  v-model="UserModel.first_name"
                  class="form-control"
                  type="text"
                  maxlength="50"
                  placeholder="First Name"
                  @focus="removeattribute($event)"
                  @blur="setattribute($event, 'First Name')"
                  v-bind:style="{ 'border-color': UserModel.firstnameColor }"
                />
              </div>
            </div>
          </div>
          <div class="row enter-name-row-gap">
            <div class="col-12">
              <div class="form-group">
                <span class="form-control-icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 50 62.5"
                    style="enable-background: new 0 0 50 62.5"
                    xml:space="preserve"
                    height="20"
                    width="20"
                  >
                    <path
                      d="M32.3,32.1c0.2-0.8,0.4-1.6,0.4-2.5v-8.9c0-4.8-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v8.9c0,0.9,0.2,1.7,0.4,2.4
	c-2.3,0.7-4.4,2.2-6.1,4.2c-2.4,3-3.8,7-3.8,11.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.7,0.6,1.4,1.4,1.4h33.9c0.7,0,1.4-0.6,1.4-1.4
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1C42.2,40.3,38,33.9,32.3,32.1z M17.8,20.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v8.9
	c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1V20.8z M11.5,38c1.4-1.7,3.2-2.9,5.1-3.4c1.6,2.3,4.2,3.8,7.3,3.8c3,0,5.7-1.5,7.3-3.8
	c4.5,1.2,7.8,6,8.2,11.8h-31C8.6,43.2,9.7,40.3,11.5,38z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  class="form-control"
                  maxlength="50"
                  placeholder="Middle Name"
                  @focus="removeattribute($event)"
                  @blur="setattribute($event, 'Middle Name')"
                  v-model="UserModel.middle_name"
                  v-bind:style="{ 'border-color': UserModel.middlenameColor }"
                />
              </div>
            </div>
          </div>
          <div class="row enter-name-row-gap">
            <div class="col-9">
              <div class="form-group">
                <span class="form-control-icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 50 62.5"
                    style="enable-background: new 0 0 50 62.5"
                    xml:space="preserve"
                    height="20"
                    width="20"
                  >
                    <path
                      d="M32.3,32.1c0.2-0.8,0.4-1.6,0.4-2.5v-8.9c0-4.8-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v8.9c0,0.9,0.2,1.7,0.4,2.4
	c-2.3,0.7-4.4,2.2-6.1,4.2c-2.4,3-3.8,7-3.8,11.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.7,0.6,1.4,1.4,1.4h33.9c0.7,0,1.4-0.6,1.4-1.4
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1C42.2,40.3,38,33.9,32.3,32.1z M17.8,20.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v8.9
	c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1V20.8z M11.5,38c1.4-1.7,3.2-2.9,5.1-3.4c1.6,2.3,4.2,3.8,7.3,3.8c3,0,5.7-1.5,7.3-3.8
	c4.5,1.2,7.8,6,8.2,11.8h-31C8.6,43.2,9.7,40.3,11.5,38z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  v-model="UserModel.last_name"
                  placeholder="Last Name"
                  class="form-control"
                  maxlength="50"
                  @focus="removeattribute($event)"
                  @blur="setattribute($event, 'Last Name')"
                  v-bind:style="{ 'border-color': UserModel.lastnameColor }"
                />
              </div>
            </div>
            <div class="col-3 suffix-padding">
              <button
                id="suffix-btn"
                @click="openSuffixModal"
                class="btn-reg-dropdown-style dropdown-toggle suffix-label"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span class="">{{ suffix }}</span>
                <span style="display: block">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="12px"
                    height="12px"
                    viewBox="0 0 56 48.5"
                    style="enable-background: new 0 0 56 48.5"
                    xml:space="preserve"
                  >
                    <path id="Polygon_1" d="M28,48.5L0,0l56,0L28,48.5z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div class="row enter-name-row-gap">
            <div class="col-12">
              <div class="form-group">
                <span class="form-control-icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 72 90"
                    style="enable-background: new 0 0 72 90"
                    xml:space="preserve"
                    height="20"
                    width="20"
                  >
                    <path
                      d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
	c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
                    />
                    <path
                      d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
                    />
                    <circle cx="36" cy="56.4" r="4" />
                  </svg>
                </span>
                <input
                  inputmode="numeric"
                  @keypress="isNumber($event)"
                  @focus="removeattribute($event)"
                  @blur="setattribute($event, 'Mobile Number')"
                  v-model="UserModel.phone"
                  placeholder="Mobile Number"
                  class="form-control"
                  v-mask="'(###) ###-####'"
                  v-bind:style="{ 'border-color': UserModel.phoneColor }"
                />
              </div>
            </div>
          </div>
          <div class="row enter-name-row-gap">
            <div class="col-12">
              <div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    v-model="checked"
                    name
                    value
                  />
                  <label for="checkbox"></label>
                </div>
              </div>
              <div
                class="terms-checkbox"
                style="margin-left: 2rem; text-align: justify"
              >
              I have read and agreed to the
                <a
                  href="https://www.canpaydebit.com/about-us/terms-conditions/"
                  class="hyperlink"
                  target="_blank"
                  >Terms of Service End User License Agreement</a>
                ,
                <a
                  href="https://www.canpaydebit.com/about-us/privacy/"
                  class="hyperlink"
                  target="_blank"
                  >Privacy Policy</a>, and to receive messages from CanPay.
              </div>
            </div>
          </div>
          <div class="row next-btn-row">
            <div class="col-12">
              <button
                type="button"
                class="btn-login"
                @click="clickSubmit"
                :disabled="!checked"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="show === constants.enter_otp">
        <enter-otp
          :PhoneNumber="UserModel.phone"
          :Fname="UserModel.first_name"
          :Mname="UserModel.middle_name"
          :Lname="UserModel.last_name"
          :SessionID="sessionID"
          :errorProp="otperror"
          :verifiedOTPProp="otpsuccess"
        ></enter-otp>
      </div>
      <div v-else-if="show === constants.enter_more_details">
        <enter-more-details
          :dobDayProp="day"
          :fulladdressProp="fulladdress"
          :addressProp="UserModel.address"
          :stateProp="UserModel.state"
          :cityProp="UserModel.city"
          :zipProp="UserModel.zip"
          :monthNumberProp="month_number"
          :dobMonthProp="dob_month"
          :dobYearProp="year"
          :emailProp="UserModel.email"
          :aptNumberProp="UserModel.aptNumber"
          v-if="!isLoading"
        ></enter-more-details>
      </div>

      <div v-else-if="show === constants.manual_review">
        <manual-review :sessionID="sessionID" v-if="!isLoading"></manual-review>
      </div>

      <div class="row" v-else-if="show === constants.enter_ssn">
        <enter-ssn v-if="!isLoading"></enter-ssn>
      </div>

      <div class="row" v-else-if="show === constants.enter_pin">
        <enter-pin v-if="!isLoading"></enter-pin>
      </div>

      <div v-else-if="show === constants.select_bank_linking || show === constants.manual">
        <bank-link-option :toogleParentLoader="toogleParentLoader" :fromParentComponent="1" :showBankType="show" :showSearchBanks="showSearchBanks"></bank-link-option>
      </div>
      <div v-else-if="show === 'success'">
        <success :sessionId="sessionID" v-if="!isLoading"></success>
      </div>
        <div class="row" v-else-if="show === constants.microbiltResponceCode29">
        <microbilt_responce_code_29 :purchasePower="perchasePower" v-if="!isLoading" ></microbilt_responce_code_29>
      </div>
      <!-- Modal for suffix options -->
      <div>
        <b-modal
          ref="suffix-modal"
          hide-footer
          hide-header
          size="sm"
          centered
          variant="primary"
          id="suffix-modal"
        >
          <div>
            <center>
              <div class="row" @click="setSuffixValue('Suffix')">
                <div class="col-12 opt-suffix">Suffix</div>
              </div>
              <div class="row" @click="setSuffixValue('I')">
                <div class="col-12 suffix-option">I</div>
              </div>
              <div class="row" @click="setSuffixValue('II')">
                <div class="col-12 suffix-option">II</div>
              </div>
              <div class="row" @click="setSuffixValue('III')">
                <div class="col-12 suffix-option">III</div>
              </div>
              <div class="row" @click="setSuffixValue('Jr.')">
                <div class="col-12 suffix-option">Jr.</div>
              </div>
              <div class="row" @click="setSuffixValue('Sr.')">
                <div class="col-12 suffix-option">Sr.</div>
              </div>
            </center>
          </div>
        </b-modal>
      </div>
      <!-- suffix modal end -->
      <!-- Modal for info icon -->
      <div>
        <b-modal
          ref="info-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          id="info_modal"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                <label class="purchasepower-def-label">
                  <b>Know more about your registration progress</b>
                </label>
              </div>
              <h3 class="purchasepower-modal-text text-center">
                This progress bar shows the completion of your enrollment
                process. Usually it takes less than 1 minute to complete.
              </h3>
              <div class="text-center">
                <button
                  type="button"
                  class="mx-auto col-10 offset-1 btn-black"
                  style="height: 60px"
                  @click="hideModal"
                >
                  <label class="purchasepower-modal-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <!-- pin created success modal -->
      <!-----------------------  MODAL FOR SUCCESS  !---------------->

      <div>
        <b-modal
          ref="pin-success-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          no-close-on-backdrop
          id="pin-success-modal"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="pin-success-top-spacing"></div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col-4"></div>
                <div class="col-4">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 125"
                    style="enable-background: new 0 0 100 125"
                    xml:space="preserve"
                    height="80"
                    width="80"
                    fill="#1b9142"
                    class="succes-svg-padding"
                  >
                    <g>
                      <g>
                        <path
                          d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                        />
                      </g>
                      <g>
                        <polygon
                          points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="col-4"></div>
              </div>
              <div class="d-block text-center">
                <label class="success-popup-style">Success!</label>
              </div>
              <div class="d-block text-center">
                <label class="quick-pin-created-style"
                  >Your Quick Access PIN was created.</label
                >
              </div>
              <div class="success-bottom-spacing"></div>
            </div>
          </div>
        </b-modal>
      </div>

      <!-- v2 update  modal -->
      <!-----------------------  MODAL FOR UPDATE  !---------------->

      <div>
        <b-modal
          ref="email-success-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          no-close-on-backdrop
          id="email-success-modal"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="pin-success-top-spacing"></div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col-12 text-center">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="120"
                    height="120"
                    viewBox="0 0 100 125"
                    style="enable-background: new 0 0 100 125"
                    xml:space="preserve"
                    fill="#e14343"
                  >
                    <path
                      d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                    />
                  </svg>
                </div>
              </div>
              <div class="d-block text-center">
                <label class="success-popup-style">{{ modalMessage }}</label>
              </div>
              <div class="success-bottom-spacing"></div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <button
                  type="button"
                  class="btn-login btn-get-started"
                  @click="updateAccount"
                >
                  Update Account
                </button>
              </div>
            </div>
          </div>
        </b-modal>

        <!-- Validation modal -->
        <b-modal
          ref="validation-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          id="validation-modal"
          centered
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                <label class="purchasepower-def-label">
                  {{ error_message }}
                </label>
              </div>
              <br />
              <br />
              <div class="text-center">
                <button
                  type="button"
                  class="mx-auto col-10 offset-1 btn-black"
                  style="height: 60px"
                  @click="hidevalidationModal(false)"
                >
                  <label class="purchasepower-modal-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- Account already exists Modal-->
        <b-modal
          ref="sign-in-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          id="sign-in-modal"
          centered
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                <label class="purchasepower-def-label">
                  {{ error_message }}
                </label>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <button
                    type="button"
                    class="btn-login btn-get-started"
                    @click="clickSignIn"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

        <!-- Validation modal  redirect step -->
        <b-modal
          ref="validation-redirect-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          no-close-on-backdrop
          id="validation-redirect-modal"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                <label class="purchasepower-def-label">
                  {{ error_message }}
                </label>
              </div>
              <br />
              <br />
              <div class="text-center">
                <button
                  type="button"
                  class="mx-auto col-10 offset-1 btn-black"
                  style="height: 60px"
                  @click="hideredirectvalidationModal()"
                >
                  <label class="purchasepower-modal-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </b-modal>

      </div>
    </div>
    <div id="overlay" style="display: none;"></div>
  </div>
</div>
</template>

<script>
/**
 * Registration component is using for consumer registration process
 */
import api from "../../api/registration.js";
import constants from "../Common/constant.js";
import EnterOTP from "./EnterOTP.vue";
import EnterMoreDetails from "./EnterMoreDetails.vue";
import EnterQuickAccessPin from "./EnterQuickAccessPin.vue";
import BankLinkingOption from "../Profile/BankLinkingOption.vue";
import ManualReview from "./ManualReview.vue";
import EnterSSN from "./EnterSSN.vue";
import Success from "./Success.vue";
import Toasted from "vue-toasted";
import Loading from "vue-loading-overlay";
import MicrobiltResponceCode29 from "./MicrobiltResponceCodeTwentynine.vue";
import MxConnectWidget from '../MxConnectWidget.vue';
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Registration",
  components: {
    "enter-otp": EnterOTP,
    "enter-more-details": EnterMoreDetails,
    "enter-pin": EnterQuickAccessPin,
    "manual-review": ManualReview,
    "enter-ssn": EnterSSN,
    "bank-link-option": BankLinkingOption,
    "microbilt_responce_code_29": MicrobiltResponceCode29,
    success: Success,
    Loading,
    MxConnectWidget,
    CanPayLoader
  },
  /**
   * @description-
   * first_name => this will store the user's first_name
   * middle_name => this will store the user's middle_name
   * last_name => this will store the user's last_name
   * phone => this will store the user's phone
   * email => this will store the user's email
   * @returns {any}
   */
  data() {
    return {
      otperror: false,
      constants: constants,
      error_message: "",
      progressMax: 100,
      progress: 20,
      suffix: "Suffix",
      show: constants.enter_name_phone,
      imageFront: "",
      imageBack: "",
      otp: "",
      sessionID: "",
      newPhonenumber: false,
      showSearchBanks: true,
      refresh: false,
      isLoading: false,
      fullPage: true,
      checked: false,
      enterOTPapiFlag: false,
      alphabet_reg: /^[a-zA-Z ]+$/,
      fulladdress: "",
      day: "",
      month_number: "",
      dob_month: "",
      year: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      UserModel: {
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        email: "",
        firstnameColor: "",
        middlenameColor: "",
        lastnameColor: "",
        phoneColor: "",
        emailColor: "",
        address: "",
        state: "",
        city: "",
        zip: "",
        routingNumber: "",
        accountNumber: "",
      },
      showFloatDiv: false,
      ssn: "",
      pin: "",
      otpsuccess: false,
      modalMessage: "",
      continueWithCodeTwintynine:false,
      perchasePower: null,
      constants: constants,
      show_manual_link: false,
      banking_solution:'akoya',
      mxConnectUrl: null,
      skip_current_banking_solution: false,
    };
  },
  created: function () {
    // let self = this;
    // setTimeout(function() {
    //  if(localStorage.getItem("session_state") == 0){
    //     self.show_manual_link = true;
    //   };
    // }, 2000);
    // console.log(444444444444444);
    // console.log(localStorage.getItem("session_state"));
    // console.log(self.show_manual_link);
  },
  mounted: function () {
    let self = this;
    self.sessionID = new Date().getTime();
    localStorage.removeItem("session_data");
    localStorage.setItem("session_data", self.sessionID);
    self.$root.$emit("show_header", true);
    if (localStorage.getItem("consumer_token") !== null) {
      localStorage.removeItem("consumer_token");
      localStorage.removeItem("consumer_login_response");
    }
    self.$root.$emit("loginapp", ["hi"]);
    self.$root.$emit("changeWhiteBackground", [false, true, "common"]);
    var element = document.getElementsByClassName("content-wrap");
    if (element.length != 0) {
      element[0].style.setProperty("background-color", "#149240");
    }
    var el = document.getElementsByClassName("wrapper");
    if (el.length != 0) {
      el[0].style.setProperty("background-color", "#149240");
    }
    //check if user tried to login and has a registration session for the same phone number
    if (localStorage.getItem("session_exists")) {
      self.sessionID = localStorage.getItem("session_exists");
      localStorage.setItem("session_data", self.sessionID);
      self.show = parseInt(localStorage.getItem("session_step"));
      localStorage.removeItem("session_exists");
      localStorage.removeItem("session_step");
    } else {
      localStorage.removeItem("microbilt_error_need_bank_link");
    }
    //catching the phone otp pin data when emitted
    this.$root.$on("EnterOtp", function (data) {
      self.otp = data[1];
      self.verifyOTP();
    });
    //catching the dob/address/ssn data when emitted
    this.$root.$on("add_address", function (data) {
      //formats the date of birth into mm-dd-yyyy format
      let date = data[0];
      var formatted_date = date[0] + "-" + date[1] + "-" + date[2];
      self.day = date[1];
      self.month_number = date[0];
      self.year = date[2];
      self.dob_month = data[4];
      self.fulladdress = data[5];
      self.UserModel.date_of_birth = formatted_date;
      let getaddress = data[1];
      self.UserModel.address = getaddress[0];
      self.UserModel.state = getaddress[1];
      self.UserModel.city = getaddress[2];
      self.UserModel.zip = getaddress[3];
      self.UserModel.email = data[2];
      self.UserModel.aptNumber = data[3];
      self.checkEmail();
    });
    this.$root.$on("loginPin", function (data) {
      self.pin = data[0];
      self.enterPIN();
    });
    this.$root.$on("bank_details", function (data) {
      self.UserModel.account_type = data[2];
      self.UserModel.accountNumber = data[1];
      self.UserModel.routingNumber = data[0];
      self.continueWithCodeTwintynine = false;
      self.showSearchBanks = false;
      self.validateManualBankAccount(self.continueWithCodeTwintynine);
    });
    this.$root.$on("show", function (data) {
      self.show = data;
    });
    this.$root.$on("emit_ssn", function (data) {
      self.ssn = data;
      self.getAssessment();
    });
    this.$root.$on("manual_review", function (data) {
      self.imageFront = data[1];
      self.imageBack = data[0];
      self.manualIdentityReview();
    });
    this.$root.$on("bank_link", function (data) {
      if (data != constants.finicity) {
        self.show = data;
      }
    });
    this.$root.$on("bank_solution", function (data) {
      self.banking_solution = data;
      self.showSearchBanks = false;
    });
    this.$root.$on("microbilt_responce_code_29", function (data) {
      if (data == constants.microbiltResponceCode29) {
      self.continueWithCodeTwintynine = true;
      self.validateManualBankAccount(self.continueWithCodeTwintynine);
      } else {
        self.show = data;
      }
    });
    //after success modal redirect to valid step
    this.$root.$on("verified_OTP", function (data) {
      self.redirectToValidStep();
    });

    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    if (this.show == constants.enter_name_phone) {
      localStorage.removeItem("skip_current_banking_solution");
    }
  },
  watch: {
    show: function (newval, oldval) {
      //set the progress value
      if (newval == constants.enter_name_phone) {
        this.progress = 20;
      } else if (newval == constants.enter_otp) {
        this.progress = 40;
      } else if (
        newval == constants.enter_more_details ||
        newval == constants.enter_ssn ||
        newval == constants.enter_pin
      ) {
        this.progress = 60;
      } else if (newval == constants.select_bank_linking) {
        this.progress = 80;
      } else if (newval == "success") {
        this.progress = 100;
      }
    },
  },
  methods: {
    toogleParentLoader(is_loading){
      this.isLoading = is_loading;
    },
    handleWidgetEvent(event) {
      let self = this;
      console.log('MX PostMessage: ', event);
      if (event.type === 'mx/connect/memberConnected') {
        self.$router.push({ name: 'mx-success', params: { 'user_guid': event.metadata.user_guid, 'member_guid':event.metadata.member_guid } })
      }else if (event.type === 'mx/connect/stepChange' && event.metadata.current == 'search' && event.metadata.previous == 'enterCreds') {
        self.$router.push({ name: 'EnterNamePhone'})
      } else if (event.type === 'mx/connect/loaded') {
        // Handle widget loading completion
        self.isLoading = false;
      }
    },
    showManual(){
      this.show = constants.manual;
      this.showFloatDiv = false;
      window.finicityConnect.destroy();
    },
    removeattribute(el) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).removeAttribute("placeholder");
      } else {
        el.target.removeAttribute("placeholder");
      }
    },
    setattribute(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal(flag) {
      this.error_message = "";
      localStorage.setItem("skip_current_banking_solution", this.skip_current_banking_solution);
      this.$refs["validation-modal"].hide();
      if (flag) {
        this.$router.push("/login");
      }
      if (this.refresh) {
        this.$router.go();
      }
    },
    showredirectValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-redirect-modal"].show();
    },
    hideredirectvalidationModal() {
      this.error_message = "";
      this.$refs["validation-redirect-modal"].hide();
      this.$router.push("/login");
    },
    setSuffixValue(val) {
      this.suffix = val;
      this.hideSuffixModal();
    },
    showModal() {
      this.$refs["info-modal"].show();
    },
    hideModal() {
      this.$refs["info-modal"].hide();
    },
    showPinSuccessModal(data) {
      var self = this;
      this.$refs["pin-success-modal"].show();
      setTimeout(function () {
        self.hidePinSuccessModal(data);
      }, 1000);
    },
    hidePinSuccessModal(data) {
      this.$refs["pin-success-modal"].hide();
      this.show = data;
    },
    openSuffixModal() {
      this.$refs["suffix-modal"].show();
      setTimeout(function () {
        var element = document.getElementsByClassName("modal-sm")[0];
        element.classList.add("suffix-modal-sm");
      }, 10);
    },
    hideSuffixModal() {
      this.$refs["suffix-modal"].hide();
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
    clickPrevious() {
      this.$router.push("/");
    },
    setDefaultdata() {
      let self = this;
      self.otp = "";
      self.checked = false;
      self.UserModel.first_name = "";
      self.UserModel.middle_name = "";
      self.UserModel.last_name = "";
      self.UserModel.phone = "";
      (self.UserModel.email = ""), (self.UserModel.firstnameColor = "");
      self.UserModel.middlenameColor = "";
      self.UserModel.lastnameColor = "";
      self.UserModel.phoneColor = "";
      self.UserModel.emailColor = "";
      self.UserModel.pin = "";
      self.UserModel.address = "";
      self.UserModel.state = "";
      self.UserModel.city = "";
      self.UserModel.zip = "";
    },
    clickSubmit() {
      let self = this;
      self.UserModel.phone = self.UserModel.phone.replace(/[^a-zA-Z0-9]/g, "");
      if (self.UserModel.first_name == "") {
        this.UserModel.firstnameColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.UserModel.last_name == "") {
        this.UserModel.lastnameColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.UserModel.phone == "") {
        this.UserModel.phoneColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.UserModel.phone.length < 10) {
        self.showValidationModal(RequiredField);
        return false;
      } else {
        let self = this;
        self.UserModel.firstnameColor = "";
        self.UserModel.middlenameColor = "";
        self.UserModel.lastnameColor = "";
        self.UserModel.phoneColor = "";
        if (!self.alphabet_reg.test(self.UserModel.first_name)) {
          self.showValidationModal("Please enter a valid name.");
          return false;
        }
        if (
          self.UserModel.middle_name != "" &&
          !self.alphabet_reg.test(self.UserModel.middle_name)
        ) {
          self.showValidationModal("Please enter a valid name.");
          return false;
        }
        if (!self.alphabet_reg.test(self.UserModel.last_name)) {
          self.showValidationModal("Please enter a valid name.");
          return false;
        }
        self.checkPhoneNumberAvailability();
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    isAlphabet: function (value) {
      if (this.alphabet_reg.test(value)) {
        return true;
      } else {
        self.showValidationModal("Please enter alphabets only.");
      }
    },
    /**
     * @description-
     * checkEmail => this function will validate email
     * @returns {any}
     */
    //API call to verify email
    checkEmail() {
      let self = this;
      if (localStorage.getItem("session_data") == self.sessionID) {
        this.isLoading = true;
        var request = {
          email: this.UserModel.email.toLowerCase(),
          sessionId: this.sessionID,
        };
        api
          .checkEmail(request)
          .then((response) => {
            if (response.code == 200) {
              self.makeidvalidation();
            }
          })
          .catch((err) => {
            self.showValidationModal(err.response.data.message);
            self.isLoading = false;
          });
      }
    },
    /**
     * @description-
     * verifyPhoneNumber => this function will validate phone number and generate otp
     * @returns {any}
     */
    //API call to verify phone number
    verifyPhoneNumber: function () {
      let self = this;
      this.isLoading = true;
      self.UserModel.phone = self.UserModel.phone.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phoneNo: this.UserModel.phone,
        sessionId: this.sessionID,
        firstName: this.UserModel.first_name,
        phoneNo: this.UserModel.phone,
        middleName: this.UserModel.middle_name,
        lastName: this.UserModel.last_name,
        suffix: this.suffix,
        from_ecommerce: this.$route.query.from_widget ? 1 : 0,
      };
      api
        .verifyPhonenumber(request)
        .then((response) => {
          //if reponse returns success
          if (response.code == 200) {
            //redirect to enter otp page
            this.isLoading = false;
            self.show = self.constants.enter_otp;
            self.progress = 40;
          }
        })
        .catch((err) => {
          self.showValidationModal(err.response.data.message);
          this.isLoading = false;
          self.refresh = false;
          if (
            typeof err.response.data.data.too_many_attempts != "undefined" ||
            err.response.data.code == 401
          ) {
            self.refresh = true;
          }
        });
    },

    checkPhoneNumberAvailability: function () {
      let self = this;
      this.isLoading = true;
      self.UserModel.phone = self.UserModel.phone.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phoneNo: this.UserModel.phone,
        sessionId: this.sessionID,
      };
      api
        .checkPhonenumber(request)
        .then((response) => {
          this.isLoading = false;
          if (response.code == 200) {
            self.verifyPhoneNumber();
          }
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response.data.data == "is_v1") {
            self.modalMessage = err.response.data.message;
            self.showUpdateModal();
          } else {
            self.newPhonenumber = false;
            self.showSignInModal(err.response.data.message);
          }
        });
    },
    /**
     * @description-
     * verifyPhoneNumber => this function will validate otp
     * @returns {any}
     */
    //API call to verify otp once verified it will proceed to do the id validation
    verifyOTP: function () {
      let self = this;
      if (localStorage.getItem("session_data") == self.sessionID) {
        self.otperror = false;
        self.UserModel.phone = self.UserModel.phone.replace(
          /[^a-zA-Z0-9]/g,
          ""
        );
        if (self.enterOTPapiFlag == true) {
        }
        var request = {
          otp: this.otp,
          phoneNo: this.UserModel.phone,
          sessionId: this.sessionID,
        };
        api
          .verifyOTP(request)
          .then((response) => {
            if (response.code == 200) {
              self.isLoading = false;
              self.otpsuccess = true;
              self.otperror = false;
            }
          })
          .catch((err) => {
            self.otpsuccess = false;
            self.otperror = true;
            self.isLoading = false;
            self.showValidationModal(err.response.data.message);
          });
      }
    },

    /**
     * @description-
     * redirectToValidStep => API call to API to redirect to valid step
     * @returns {any}
     */
    redirectToValidStep() {
      let self = this;
      if (localStorage.getItem("session_data") == this.sessionID) {
        this.isLoading = true;
        var request = {
          sessionId: this.sessionID,
          phoneNo: this.UserModel.phone,
        };
        api
          .redirectToValidStep(request)
          .then((response) => {
            this.isLoading = false;
            this.show = response.data;
          })
          .catch((err) => {
            this.isLoading = false;
            self.showredirectValidationModal(err.response.data.message, true);
          });
      }
    },
    /**
     * @description-
     * getAssessment => API call to get the asessment for last identity search with ssn
     * @returns {any}
     */
    getAssessment() {
      let self = this;
      if (localStorage.getItem("session_data") == this.sessionID) {
        this.isLoading = true;
        var request = {
          ssn: this.ssn,
          sessionId: this.sessionID,
        };
        api
          .getAssessment(request)
          .then((response) => {
            if (response.code == 200) {
              self.show = response.data;
              this.isLoading = false;
            }
          })
          .catch((err) => {
            console.log("error", err);
            this.isLoading = false;
            self.showValidationModal(err.response.data.message);
          });
      }
    },
    /**
     * @description-
     * getAssessment => API call to get the asessment for last identity search with ssn
     * @returns {any}
     */
    enterPIN() {
      if (localStorage.getItem("session_data") == this.sessionID) {
        this.isLoading = true;
        var request = {
          pin: this.pin,
          sessionId: this.sessionID,
        };
        api
          .enterPIN(request)
          .then((response) => {
            if (response.code == 200) {
              this.isLoading = false;
              this.showPinSuccessModal(response.data);
            }
          })
          .catch((err) => {
            this.isLoading = false;
            self.showValidationModal(err.response.data.message);
          });
      }
    },
    //API call to verify all details to do the id validation
    makeidvalidation: function () {
      let self = this;
      if (localStorage.getItem("session_data") == this.sessionID) {
        self.UserModel.phone = self.UserModel.phone.replace(
          /[^a-zA-Z0-9]/g,
          ""
        );
        var request = {
          email: this.UserModel.email.toLowerCase(),
          dateOfBirth: this.UserModel.date_of_birth,
          address: this.UserModel.address,
          city: this.UserModel.city,
          state: this.UserModel.state,
          zip: this.UserModel.zip,
          aptNumber: this.UserModel.aptNumber,
          sessionId: this.sessionID,
        };
        api
          .makeIdvalidation(request)
          .then((response) => {
            this.isLoading = false;
            if (response.data != null) {
              self.show = response.data;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            self.showValidationModal(err.response.data.message);
          });
      }
    },
    validateManualBankAccount(continueWithCodeTwintynine) {
      let self = this;

      if (localStorage.getItem("session_data") == this.sessionID) {
        var request = {
          sessionId: this.sessionID,
        };
        if (
          self.UserModel.accountNumber === "" &&
          self.UserModel.routingNumber === ""
        ) {
          self.showValidationModal("Please fill in the required fields.");
          return false;
        }
			  var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
        self.isLoading = true;
        request.accountNumber = self.UserModel.accountNumber;
        request.routingNumber = self.UserModel.routingNumber;
        request.account_type = self.UserModel.account_type;
        request.continueWithCodeTwintynine = continueWithCodeTwintynine;
				request.bank_name = selected_bank.bank_name;
        request.bank_id = selected_bank.id;
        request.batch_id = selected_bank.batch_id;
        api
          .validateManualBankAccount(request)
          .then((response) => {
            if (response.code == 200) {
              self.isLoading = false;
              localStorage.setItem("user_id", response.data.user_id);
              self.show = "success";

              var currentSlug = "registrationsuccess";
              let stateObj = { id: currentSlug };
              window.history.pushState(stateObj,
              currentSlug, currentSlug);

              if (
                typeof response.data.global_radar != "undefined" &&
                response.data.global_radar == 1
              ) {
                self.showValidationModal(
                  "Registration Complete. You may now login."
                );
              }
            }
          })
          .catch((err) => {
            this.isLoading = false;
            if(err.response.data.code == 595){
              self.showSignInModal(err.response.data.message);
            } else if(err.response.data.code != 503) {
              self.showValidationModal(err.response.data.message);
              self.show = constants.select_bank_linking;
            }
           
            if(err.response.data.data == 'Error:3001'){
              localStorage.setItem("microbilt_error_need_bank_link", true);
            }

            if (err.response.data.code == 401) {
              self.$router.go();
            }
            if (err.response.data.code == 598) {
              self.$router.push("/login");
            }
            if(err.response.data.code == 503)
            {
              self.show = constants.microbiltResponceCode29;
              self.continueWithCodeTwintynine = true;
              self.perchasePower = err.response.data.data;
            }
          });
      }
    },
    showUpdateModal() {
      var self = this;
      this.$refs["email-success-modal"].show();
    },
    showSignInModal(message) {
      this.error_message = message;
      this.$refs["sign-in-modal"].show();
    },
    clickSignIn() {
      this.error_message = "";
      this.$refs["sign-in-modal"].hide();
      this.$router.push("/login");
    },
    updateAccount() {
      this.$router.push("/upgradecanpaywithemail");
    },
  },
};
</script>
<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (min-width: 300px) {
  .suffix-modal-sm {
    float: right !important;
  }
}
@media only screen and (width: 360px) and (width: 640px) {
  .suffix-modal-sm {
    float: right !important;
    margin-top: -5px;
    width: 94px;
  }
}
@media only screen and (min-width: 360px) and (min-height: 700px) {
  .suffix-modal-sm {
    float: right !important;
  }
}
@media only screen and (max-width: 375px) and (max-width: 812px) {
  .suffix-modal-sm {
    float: right !important;
    margin-top: -58px;
    width: 95px;
  }
}
@media only screen and (width: 600px) and (height: 960px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 100px;
    margin-top: -110px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 120px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 120px;
    margin-top: -190px;
  }
}
@media (min-width: 1281px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 293px;
    margin-top: 14px;
  }
}
#suffix-modal___BV_modal_content_ {
  margin-top: 165px;
  height: 238px;
  width: 66px;
}
#suffix-modal___BV_modal_body_ {
  background-color: white !important;
  border-radius: 4px;
}
#info_modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#info_modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#pin-success-modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pin-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#sign-in-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#validation-redirect-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
