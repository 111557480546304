<template>
    <div class="pb-2">
        <div >
            <p style="padding:  14px 17px 10px 17px;" :id="'range_text_'+ownedPoints" class="cp-point-convertion-text text-left mb-0"><span>Up to <strong class="text-white value">${{amountNumberFormatter(value*exchangeRate)}}</strong> will be paid with {{parseInt(selectedPoints) > 1 ? 'points' : 'points'}} </span></p>

            <div style="padding: 10px 17px 7px 17px;" class="row justify-content-between mx-2 margin-bottom-reduce">
              <span class="cp-range-text ml-4">0</span>
              <span class="cp-range-text mr-3">{{pointNumberFormatter(ownedPoints)}}</span>
            </div>


            <div v-if="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints)" style="padding: 0px 17px;" class="d-flex align-items-center justify-content-between">
              <div style="margin-left: -7px;" @click="decreasePoint()" v-longclick="() => longPressDecreasePoint()" class="point-navigate-btn">
                <svg width="32" height="6" viewBox="0 0 32 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="6" height="32" rx="3" transform="rotate(-90 0 6)" fill="white"/>
                </svg>
              </div>
              <div class="cp-range-slider">
                <CustomSlider
                v-model="value"
                :total-points="parseInt(ownedPoints)"
                :merchant-points="parseInt(merchantPoints)"
                :generic-points="parseInt(genericPoints)"
                :minimum-redeem-points="parseInt(minimumRedeemPoints)"
                :is-merchant-available="parseInt(merchantPoints) >= parseInt(minimumRedeemPoints) ? true : false"
                @input="selectPrice"
                @change="slideChange($event)"  
                />
              </div>
              <div style="margin-right: -7px;" @click="increasePoint()" v-longclick="() => longPressIncreasePoint()" class="point-navigate-btn">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13" width="6" height="32" rx="3" fill="white"/>
                <rect y="19" width="6" height="32" rx="3" transform="rotate(-90 0 19)" fill="white"/>
                </svg>
              </div>
            </div>

            <div v-else style="padding: 0px 17px;" class="d-flex align-items-center justify-content-between mt-2">
              <div class="point-navigate-btn disabled">
                <svg width="32" height="6" viewBox="0 0 32 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="6" height="32" rx="3" transform="rotate(-90 0 6)" fill="white"/>
                </svg>
              </div>
              <div class="cp-range-slider-disabled">
                <div class="cp-slider-disabled"></div>
                <div class="slider-range-disabled-1"></div>
                <div class="slider-range-disabled-2"></div>
                <div class="slider-range-disabled-3"></div>
                <div class="slider-range-disabled-4"></div>
              </div>
              <div class="point-navigate-btn disabled">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13" width="6" height="32" rx="3" fill="white"/>
                <rect y="19" width="6" height="32" rx="3" transform="rotate(-90 0 19)" fill="white"/>
                </svg>
              </div>
            </div>
            <!-- NEED LATER  -->
            <div style="padding: 10px 17px 0 17px;" class="rw-point-checkbox" v-if="parseInt(merchantPoints) >= parseInt(minimumRedeemPoints)">
              <div class="rw-point-card merchant">
                <div :class="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ? 'rw-point mr-2' : 'rw-point disabled mr-2'"></div>
                <div> {{ merchantName }} Points: <strong>{{ pointNumberFormatter(merchantPoints) }} </strong>
                <svg width="15" height="15" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.6484 32.2123C45.9758 27.2992 45.0233 22.6013 44.7537 21.3551C44.4482 19.9476 43.6754 16.6214 40.8627 13.6359C39.5231 12.233 37.9321 11.0927 36.1719 10.2738C33.7996 9.17106 31.7777 8.95589 30.6724 8.8483C26.1164 8.40003 22.3512 9.72692 20.2485 10.6773C21.2723 9.69865 22.4425 8.88501 23.7171 8.26555C24.8436 7.72841 26.0375 7.34542 27.2667 7.12693C29.2792 6.74323 31.3425 6.70382 33.3683 7.01038C34.3028 7.15383 38.2028 7.82624 42.0938 10.8386C47.7641 15.2228 50.0915 21.3103 48.7705 28.3123C46.7576 39.0261 36.4595 45.3019 26.0176 42.5406C22.9443 41.7247 19.7093 39.7971 18.2715 37.9144C18.3434 37.9144 18.4243 37.8964 18.4692 37.9233C18.8287 38.1564 19.1791 38.4075 19.5476 38.6406C25.3886 42.2985 31.3733 42.3254 37.3222 39.053C38.0754 38.6376 38.791 38.1577 39.4609 37.6185C42.0399 35.5385 43.2171 33.1178 43.6484 32.2123Z" fill="black"/>
                <path d="M49.2647 32.8578C49.2018 33.2344 49.094 33.7723 48.9232 34.4088C47.6831 38.9633 44.8525 41.8412 43.6394 43.0426C39.2002 47.4446 34.0332 48.7177 31.6698 49.2736C26.8083 50.4122 22.8814 49.9281 21.4706 49.7039C16.3934 48.897 12.9067 46.8529 11.8374 46.1805C9.65575 44.8138 7.70037 43.117 6.04133 41.1509C4.1037 38.8639 2.58573 36.2538 1.55724 33.4406C1.29664 32.7233 0.308168 29.9082 0.0565558 26.053C-0.177084 22.3772 0.380057 19.6337 0.514849 18.9972C0.954323 16.9176 1.66425 14.9043 2.62659 13.0083C3.12982 12.04 5.69087 7.30621 11.3521 3.77381C12.6641 2.94899 16.0968 1.01244 20.9583 0.277275C22.2434 0.0800349 33.3233 -1.4441 41.2581 5.48622C44.9334 8.69586 47.099 12.9903 47.4405 13.6807C47.9662 14.747 48.4225 15.8461 48.8064 16.971C48.6806 16.8006 47.746 15.4917 47.7191 15.4558C44.2684 10.3545 40.2606 7.95172 40.2606 7.95172C38.7451 7.06349 37.1157 6.38487 35.4171 5.93449C30.0164 4.48208 25.4694 5.77311 24.3911 6.09587C18.0648 7.98758 14.3176 13.0172 14.1019 13.3131C10.2828 18.5668 10.3637 24.0537 10.4356 25.5778C10.6782 30.7061 12.9247 34.3281 13.9491 35.7895C14.03 35.8881 14.1468 36.0406 14.2906 36.2199C14.3985 36.3633 14.5063 36.5068 14.6141 36.6502C17.292 40.1647 20.8415 42.6123 24.7864 43.8316C27.7263 44.7285 30.8328 44.9427 33.8684 44.4579C36.9039 43.9731 39.7882 42.802 42.3004 41.0343C44.511 39.4743 45.85 37.8516 46.6767 36.8474C47.773 35.5206 48.5728 34.2116 48.8334 33.6916C48.8693 33.6288 49.2467 32.8578 49.2647 32.8578Z" fill="#ECB800"/>
                <path d="M20.3742 32.9385C15.6295 32.9385 13.2571 29.8096 13.2571 25.0131C13.2571 20.0552 15.7553 17.0697 20.6438 17.0697C22.5309 17.0697 24.0495 17.5179 25.2177 18.4683C26.314 19.4455 26.9251 20.7724 27.0778 22.4579H25.1728C24.4898 22.4579 23.9956 22.1441 23.6991 21.5255C23.1779 20.4227 22.1534 19.8579 20.6527 19.8579C17.7233 19.8579 16.4203 21.92 16.4203 25.022C16.4203 28.0344 17.6693 30.1592 20.5539 30.1592C22.5309 30.1592 23.6631 29.0744 24.0046 27.371H27.0689C26.7903 30.9213 24.2832 32.9385 20.3742 32.9385Z" fill="black"/>
                <path d="M36.756 17.1055H31.3733C30.4657 17.1055 29.7468 17.8944 29.7468 18.7999V32.8847H32.9818V27.2634H36.9896C40.1617 27.2634 41.8601 25.291 41.8601 22.1799C41.8601 18.8986 40.1168 17.1055 36.756 17.1055ZM36.3876 24.4123H33.2334V19.8399H36.5493C38.059 19.8399 38.8318 20.602 38.8318 22.1351C38.8408 23.6682 38.032 24.4392 36.3876 24.4123Z" fill="#ECB800"/>
                </svg>
                </div>
              </div>
            </div>

            <div style="padding: 0 17px 10px 17px;" class="rw-point-checkbox mt-2">
              <div class="rw-point-card generic">
                <div :class="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ? 'rw-point mr-2' : 'rw-point disabled mr-2'"></div>
                <span>CanPay Points: <strong>{{pointNumberFormatter(genericPoints)}} </strong>
                <svg width="15" height="15" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48.5822 36.0779C51.1727 30.5752 50.1125 25.3135 49.8125 23.9178C49.4724 22.3413 48.6122 18.616 45.4816 15.2722C43.9906 13.701 42.2198 12.4238 40.2606 11.5067C37.6201 10.2716 35.3697 10.0306 34.1395 9.91014C29.0685 9.40808 24.8777 10.8942 22.5373 11.9586C23.6768 10.8625 24.9793 9.95126 26.398 9.25746C27.6518 8.65587 28.9807 8.22692 30.3488 7.98221C32.5888 7.55247 34.8853 7.50832 37.14 7.85167C38.1802 8.01233 42.5211 8.76543 46.8519 12.1393C53.1631 17.0495 55.7536 23.8676 54.2833 31.7099C52.0429 43.7093 40.5807 50.7382 28.9585 47.6455C25.5378 46.7317 21.9372 44.5728 20.3369 42.4641C20.4169 42.4641 20.5069 42.444 20.5569 42.4742C20.957 42.7352 21.347 43.0164 21.7571 43.2775C28.2584 47.3744 34.9196 47.4045 41.5409 43.7394C42.3793 43.2742 43.1758 42.7366 43.9213 42.1328C46.7919 39.8032 48.1021 37.092 48.5822 36.0779Z" fill="black"/>
                <path d="M54.8333 36.8007C54.7633 37.2225 54.6433 37.825 54.4533 38.5379C53.073 43.6389 49.9224 46.8622 48.5722 48.2077C43.6312 53.138 37.8801 54.5639 35.2496 55.1864C29.8386 56.4617 25.4678 55.9195 23.8975 55.6684C18.2464 54.7647 14.3657 52.4753 13.1754 51.7222C10.7472 50.1915 8.57078 48.2911 6.72421 46.089C4.56756 43.5276 2.87801 40.6043 1.73327 37.4534C1.44321 36.6501 0.343002 33.4972 0.0629485 29.1794C-0.197101 25.0624 0.423017 21.9898 0.573045 21.2769C1.06219 18.9478 1.85236 16.6928 2.92349 14.5692C3.4836 13.4848 6.33414 8.18295 12.6353 4.22667C14.0956 3.30287 17.9163 1.13394 23.3274 0.310548C24.7576 0.0896391 37.09 -1.61739 45.9217 6.14456C50.0124 9.73936 52.4229 14.5492 52.803 15.3223C53.3881 16.5166 53.8959 17.7476 54.3232 19.0075C54.1832 18.8167 53.143 17.3507 53.113 17.3105C49.2723 11.597 44.8114 8.90593 44.8114 8.90593C43.1247 7.91111 41.3111 7.15105 39.4204 6.64663C33.4093 5.01993 28.3483 6.46589 27.1481 6.82737C20.1068 8.9461 15.936 14.5793 15.6959 14.9106C11.4451 20.7949 11.5351 26.9402 11.6151 28.6472C11.8852 34.3908 14.3857 38.4475 15.5259 40.0843C15.6159 40.1947 15.7459 40.3654 15.906 40.5663C16.026 40.7269 16.146 40.8876 16.266 41.0482C19.2466 44.9844 23.1973 47.7257 27.5882 49.0913C30.8603 50.0959 34.318 50.3358 37.6967 49.7928C41.0754 49.2498 44.2856 47.9383 47.0819 45.9584C49.5423 44.2112 51.0326 42.3938 51.9528 41.2691C53.173 39.783 54.0632 38.317 54.3533 37.7346C54.3933 37.6643 54.8133 36.8007 54.8333 36.8007Z" fill="#007EE5"/>
                <path d="M22.6772 36.8912C17.3962 36.8912 14.7557 33.3868 14.7557 28.0147C14.7557 22.4618 17.5362 19.1181 22.9772 19.1181C25.0776 19.1181 26.768 19.6201 28.0682 20.6845C29.2884 21.779 29.9686 23.2651 30.1386 25.1529H28.0182C27.2581 25.1529 26.7079 24.8015 26.3779 24.1086C25.7978 22.8735 24.6576 22.2409 22.9872 22.2409C19.7266 22.2409 18.2763 24.5504 18.2763 28.0247C18.2763 31.3986 19.6666 33.7784 22.8772 33.7784C25.0776 33.7784 26.3379 32.5634 26.7179 30.6556H30.1286C29.8185 34.6319 27.028 36.8912 22.6772 36.8912Z" fill="black"/>
                <path d="M40.9107 19.1581H34.9195C33.9093 19.1581 33.1092 20.0418 33.1092 21.056V36.8309H36.7099V30.535H41.1707C44.7014 30.535 46.5917 28.3259 46.5917 24.8415C46.5917 21.1664 44.6514 19.1581 40.9107 19.1581ZM40.5006 27.3418H36.9899V22.2208H40.6806C42.3609 22.2208 43.2211 23.0743 43.2211 24.7913C43.2311 26.5084 42.3309 27.372 40.5006 27.3418Z" fill="#007EE5"/>
                </svg>
                </span>
              </div>
            </div>
        </div> 
    </div>
</template>

<script>
import Vue from 'vue'

import CustomSlider from './CustomSlider.vue';

export default {
    props:{
        value: Number,
        ownedPoints: {
            type: Number,
            default: 0
        },
        disabled:{
          type: Boolean,
          default: false
        },
        exchangeRate: {
            type: Number,
            default: 0
        },
        minimumRedeemPoints: {
            type: Number,
            default: 0
        },
        genericPoints: {
            type: Number,
            default: 0
        },
        merchantPoints: {
            type: Number,
            default: 0
        },
        merchantName: {
            type: String,
            default: null
        },
    },
    components: {
      CustomSlider
    },
    directives:{
      'longclick': {
        bind: function (el, binding, vNode) {
          if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[longclick:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}' ` }
            console.warn(warn) // eslint-disable-line
          }

          let pressTimer = null
          let pressInterval = null

          const start = (e) => {

            if (e.type === 'click' && e.button !== 0) {
              return
            }

            if (pressTimer === null) {
              pressTimer = setTimeout(() => {
                if (50 && 50 > 0) {
                  pressInterval = setInterval(() => {
                    handler()
                  }, 40)
                }
                handler()
              }, 400)
            }
          }

          // Cancel Timeout
          const cancel = () => {
            if (pressTimer !== null) {
              clearTimeout(pressTimer)
              pressTimer = null
            }
            if (pressInterval) {
              clearInterval(pressInterval)
              pressInterval = null
              vNode.context.onReleaseLongPress()
            }
          }
          // Run Function
          const handler = (e) => {
            binding.value(e)
          }

          ;['mousedown', 'touchstart'].forEach(e => el.addEventListener(e, start))
          ;['click', 'touchend', 'touchcancel'].forEach(e => el.addEventListener(e, cancel))
        }
      }
    },  
    data(){
        return{
            selectedPoints: 0,
            minimumSpendPoints: null,
            minimumRedeemValue: 0,
            selectedAmount: 0,
            totalSelectedPoint: 0,
            pressingTimeInseconds: 0,
            maxSelectionPoint: 0,
        }
    },
    mounted(){
        var self = this;
        self.minimumSpendPoints = self.value
        self.selectedPoints = self.value
        self.minimumRedeemValue = self.minimumRedeemPoints

        self.maxSelectionPoint = parseInt(self.ownedPoints/self.minimumRedeemPoints) * self.minimumRedeemPoints
    },
    methods:{
        decreasePoint(){
          this.totalSelectedPoint = 0
          this.totalSelectedPoint = parseFloat(this.value) - parseFloat(this.minimumRedeemPoints)
          if(this.totalSelectedPoint < 0){
            this.$emit('input', 0);
            this.$emit('change', parseInt(0));
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', this.totalSelectedPoint);
          this.$emit('change', parseInt(this.totalSelectedPoint));
        },
        longPressDecreasePoint(){

          this.pressingTimeInseconds += 1

          this.totalSelectedPoint = 0
          if(this.pressingTimeInseconds < 30){
            this.totalSelectedPoint = parseFloat(this.value) - parseFloat(this.minimumRedeemPoints)
          }else if(this.pressingTimeInseconds >= 30 && this.pressingTimeInseconds < 80){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*50)
          }else if(this.pressingTimeInseconds >= 80 && this.pressingTimeInseconds < 130){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*100)
          }else if(this.pressingTimeInseconds >= 130){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*150)
          }
          
          if(this.totalSelectedPoint < 0){
            this.$emit('input', 0);
            this.totalSelectedPoint = 0
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', this.totalSelectedPoint);
        },
        increasePoint(){
          this.totalSelectedPoint = 0
          this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          if(this.totalSelectedPoint > this.ownedPoints){
            this.totalSelectedPoint = parseFloat(this.totalSelectedPoint) - parseFloat(this.minimumRedeemPoints)
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', parseInt(this.totalSelectedPoint));
          this.$emit('change', parseInt(this.totalSelectedPoint));
        },
        longPressIncreasePoint(){

          this.pressingTimeInseconds += 1

          this.totalSelectedPoint = 0
          if(this.pressingTimeInseconds < 30){
            this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          }else if(this.pressingTimeInseconds >= 30 && this.pressingTimeInseconds < 80){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*50)
          }else if(this.pressingTimeInseconds >= 80 && this.pressingTimeInseconds < 130){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*100)
          }else if(this.pressingTimeInseconds >= 130){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*150)
          }else{
            this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          }
          this.maxSelectionPoint = parseInt(this.ownedPoints/this.minimumRedeemPoints) * this.minimumRedeemPoints;
          if(this.totalSelectedPoint > this.ownedPoints){
            this.totalSelectedPoint = parseFloat(this.maxSelectionPoint)
            this.$emit('input', parseInt(this.totalSelectedPoint));
            this.selectedPoints = this.totalSelectedPoint
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', parseInt(this.totalSelectedPoint));
        },
        selectPrice(event){
          var self = this;
          self.selectedPoints = event
          self.$emit('input', parseInt(event));
          self.selectedAmount = event*self.exchangeRate
        },
        slideChange: function(event) {
            var self = this;
            self.$emit('input', parseInt(event));
            self.$emit('change', parseInt(event));
            self.selectedAmount = event*self.exchangeRate
        },
        onReleaseLongPress() {
          this.pressingTimeInseconds = 0
          this.$emit('change', parseInt(this.totalSelectedPoint));
        }
    }
}
</script>


<style lang="scss">
.cp-range-slider{
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}

.cp-slider-disabled{
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.29) 0.01%, rgba(0, 126, 229, 0) 96.86%)!important;
  background-color: #EFEFEF!important;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: no-drop;
}
.cp-range-slider-disabled{
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}
.slider-range-disabled-1{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 20%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-2{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 40%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-3{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 60%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-4{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 80%;
  position: absolute;
  bottom: 0;
}



.cp-range-text{
  color: #ffffff;
  font-size: 13px !important;
  font-weight: 600;
}
.point-navigate-btn{
  background-color: #0e5e2d;
  border: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}
.point-navigate-btn.disabled{
  cursor: no-drop;
}
.rw-point-checkbox{
  display: flex;
  justify-content: center;
}
.rw-point-card{
  width: 92%;
  display: flex;
}
.rw-point-card.generic{
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rw-point-card.generic .rw-point{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #007ee5;
  border: 1px solid #000;
}
.rw-point-card.merchant{
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rw-point-card.merchant .rw-point{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #ebb700;
  border: 1px solid #000;
}
.rw-point.disabled{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #C7D0D7!important;
  border: 1px solid #000;
}
.cp-range-disabled-input{
  background-color: #ACACAC;
  height: 20px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: no-drop;
}
.margin-bottom-reduce{
  margin-bottom: -5px;
}
.merchant-points-range{
  width: 20%;
  height: 20px;
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
@media only screen and ( min-width:280px) and ( max-width:700px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 80%;
  }
  .rw-point-card.generic{
    font-size: 8px;
  }
  .rw-point-card.merchant{
    font-size: 8px;
  }
}
@media only screen and ( min-width:320px) and ( max-width:700px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 80%;
  }
  .rw-point-card.generic{
    font-size: 9px;
  }
  .rw-point-card.merchant{
    font-size: 9px;
  }
}
@media only screen and ( min-width:376px) and ( max-width:800px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 84%;
  }
  .rw-point-card.generic{
    font-size: 11px;
  }
  .rw-point-card.merchant{
    font-size: 11px;
  }
}
</style>